import React from 'react';
import './MaintenancePage.css';

const MaintenancePage = () => {
  return (
    <div className="maintenance-page">
      <h1>Maintenance Mode</h1>
      <p>Our website is currently undergoing scheduled maintenance. We should be back shortly. Thank you for your patience.</p>
    </div>
  );
};

export default MaintenancePage;
