import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import ChartAnnotation from 'chartjs-plugin-annotation';

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartAnnotation,
  Filler
);

const calculateMovingAverage = (data, windowSize) => {
  let movingAverages = [];
  for (let i = 0; i < data.length; i++) {
    if (i < windowSize - 1) {
      movingAverages.push(data[i]);
    } else {
      let window = data.slice(i - windowSize + 1, i + 1);
      let average = window.reduce((sum, val) => sum + val, 0) / windowSize;
      movingAverages.push(average);
    }
  }
  return movingAverages;
};

const ChartPage = () => {
  const [performanceRecords, setPerformanceRecords] = useState([]);
  const [firstStratTrades, setFirstStratTrades] = useState([]);
  const [secondStratTrades, setSecondStratTrades] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);

  const fetchData = async () => {
    try {
      const performanceResponse = await fetch(`/api/signals`);
      const performanceData = await performanceResponse.json();
      const tradesResponse = await fetch(`/api/stratA`);
      const tradesData = await tradesResponse.json();
      const trades2Response = await fetch(`/api/stratB`);
      const trades2Data = await trades2Response.json();
      if (performanceData.performanceRecords) {
        const reversedData = performanceData.performanceRecords.reverse();
        setPerformanceRecords(reversedData);
        setFirstStratTrades(tradesData.tradesRecords);
        setSecondStratTrades(trades2Data.tradesRecords2);
        setRefreshKey(prevKey => prevKey + 1);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 60000);

    return () => clearInterval(interval);
  }, []);

  // Ensure price_delta_list is consistent
  const PDValues = performanceRecords.map(record => record.price_delta);

  const shortTermMA = calculateMovingAverage(PDValues, 3);
  const longTermMA = calculateMovingAverage(PDValues, 12);

  console.log("Short Term MA Last Value:", shortTermMA[shortTermMA.length - 1]);
  console.log("Long Term MA Last Value:", longTermMA[longTermMA.length - 1]);

  const chartData = {
    labels: performanceRecords.map(record => new Date(record.timestamp)),
    datasets: [
      {
        label: 'V',
        data: performanceRecords.map(record => record.unrealized_pnl + record.total_pnl),
        borderColor: 'lightgreen',
        fill: false,
        pointRadius: 0,
        borderWidth: 0.8,
        yAxisID: 'y-axis-1',
        tension: 0.1,
        hidden: false,
      },
      {
        label: 'SB',
        data: performanceRecords.map(record => record.unrealized_pnl_2 + record.total_pnl_2),
        borderColor: 'cyan',
        fill: false,
        pointRadius: 0,
        borderWidth: 0.8,
        yAxisID: 'y-axis-3',
        tension: 0.1,
        hidden: true,
      },
      {
        label: 'Vol.',
        data: performanceRecords.map(record => record.price),
        pointBackgroundColor: performanceRecords.map(record => record.position === 'LONG' ? 'rgba(0,255,255,0.5)' : 'rgba(227,61,148,0.5)'),
        fill: false,
        pointRadius: performanceRecords.map(record => record.btcusdt_volume / 10),
        showLine: false,   
        yAxisID: 'y-axis-6',
        showLine: false,
        hidden: false,
      },
      {
        label: 'BTC',
        data: performanceRecords.map(record => record.price),
        borderColor: '#D2B4DE',
        backgroundColor: 'rgba(58, 151, 34, 0.1)',
        fill: false,
        borderWidth: 0.5,
        pointRadius: 0,
        yAxisID: 'y-axis-5',
        hidden: true,
      },
      {
        label: 'Trades',
        data: firstStratTrades.map(trade => ({
          x: new Date(trade.timestamp),
          y: trade.unrealized_pnl,
        })),
        pointBackgroundColor: firstStratTrades.map(trade => trade.position === 'SHORT' ? 'red' : 'blue'),
        pointBorderColor: firstStratTrades.map(trade => trade.position === 'SHORT' ? 'red' : 'blue'),
        fill: false,
        pointRadius: 6,
        borderWidth: 0,
        showLine: false, 
        yAxisID: 'y-axis-trades',
        hidden: true,
      },
      {
        label: 'Trades 2',
        data: secondStratTrades.map(trade => ({
          x: new Date(trade.timestamp),
          y: trade.unrealized_pnl,
        })),
        pointBackgroundColor: secondStratTrades.map(trade => trade.position === 'SHORT' ? 'pink' : 'cyan'),
        pointBorderColor: secondStratTrades.map(trade => trade.position === 'SHORT' ? 'pink' : 'cyan'),
        fill: false,
        pointRadius: 6,
        borderWidth: 0,
        showLine: false, 
        yAxisID: 'y-axis-trades',
        hidden: true,
      },
    ],
  };

  const midnightAnnotations = performanceRecords
    .filter(record => new Date(record.timestamp).getHours() === 0 && new Date(record.timestamp).getMinutes() === 0)
    .map(record => ({
      type: 'line',
      xMin: new Date(record.timestamp),
      xMax: new Date(record.timestamp),
      borderColor: 'black',
      borderWidth: 1,
      label: {
        content: 'Midnight',
        enabled: true,
        position: 'top',
      },
    }));

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'minute',
        },
      },
      'y-axis-1': {
        type: 'linear',
        position: 'left',
      },
      'y-axis-3': {
        type: 'linear',
        position: 'right',
      },
      'y-axis-trades': {
        type: 'linear',
        position: 'right',
        grid: {
          color: (context) => context.tick.value === 0 ? '#777' : '#444',
        },
      },
    },
    plugins: {
      annotation: {
        annotations: [
          ...midnightAnnotations,
        ],
      },
    },
    layout: {
      padding: {
        left: 35,
        right: 40,
        top: 30,
        bottom: 35,
      },
    },
  };

  return (
    <div key={refreshKey} className="chart-container" style={{ width: '100%', height: '80vh' }}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default ChartPage;
