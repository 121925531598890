import React, { useEffect, useState } from 'react';
import './SignalFeed.css';

const SignalFeed = ({ signals }) => {
  const [firstStratTrades, setFirstStratTrades] = useState([]);
  const [secondStratTrades, setSecondStratTrades] = useState([]);

  useEffect(() => {
    const fetchFirstStratInfo = async () => {
      try {
        const response = await fetch(`/api/stratA`);
        const data = await response.json();
        setFirstStratTrades(data.tradesRecords);
      } catch (error) {
        console.error('Error fetching Strat A info:', error);
      }
    };

    const fetchSecondStratInfo = async () => {
      try {
        const response = await fetch(`/api/stratB`);
        const data = await response.json();
        setSecondStratTrades(data.tradesRecords2);
      } catch (error) {
        console.error('Error fetching Strat B info:', error);
      }
    };

    fetchFirstStratInfo();
    fetchSecondStratInfo();
  }, []);

  const getBackgroundColorClass = (position) => {
    if (position === 'LONG') return 'long';
    if (position === 'SHORT') return 'short';
    return ''; // Default class
  };

  const findRelevantTrades = (trades, signalTimestamp) => {
    if (!trades.length) return [];
    const signalTime = new Date(signalTimestamp);
    const signalTimeStr = signalTime.toISOString().substring(0, 16); // Get YYYY-MM-DDTHH:MM

    return trades.filter(trade => {
      const tradeTime = new Date(trade.timestamp);
      const tradeTimeStr = tradeTime.toISOString().substring(0, 16); // Get YYYY-MM-DDTHH:MM
      const openTime = new Date(trade.timestamp_open).getTime();
      const closeTime = trade.timestamp_close ? new Date(trade.timestamp_close).getTime() : null;
      const signalTimestampTime = signalTime.getTime();

      return (tradeTimeStr === signalTimeStr) || 
             (closeTime === null && openTime <= signalTimestampTime) ||
             (openTime <= signalTimestampTime && signalTimestampTime <= closeTime) &&
             (closeTime === null || signalTimestampTime <= closeTime);
    }).sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
  };

  return (
    <div className="signal-feed">
      <h3>Signal Feed</h3>
      <div className="signals-container">
        {signals.length === 0 ? (
          <p>No signals found.</p>
        ) : (
          signals.map((signal, index) => {
            const relevantFirstStratTrades = findRelevantTrades(firstStratTrades, signal.timestamp);
            const relevantSecondStratTrades = findRelevantTrades(secondStratTrades, signal.timestamp);

            return (
              <div key={index} className="signal-record">
                <div className={`signal-info ${getBackgroundColorClass(signal.position)}`}>
                  <p><strong>{signal.timestamp}</strong></p>
                  <p>Position: <strong>{signal.position}</strong> </p>
                  <p>vol: {signal.btcusdt_volume}</p>
                  <p>conf: {signal.confidence}</p>
                </div>
                <div className="chart-image-container">
                  <img className="chart-image" src={signal.chart_url} alt="Chart" style={{ maxWidth: '980px' }} />
                </div>
                <div className="strategies-info">
                  <div className="api-result-container">
                    <code>
                      {signal.api_result}
                    </code>
                  </div>
                </div>
                <div className="strategies-info">
                  {relevantFirstStratTrades.map((trade, idx) => (
                    <div
                      key={idx}
                      className={`strategy-info ${getBackgroundColorClass(trade.position)}`}
                    >
                      <h3>Vision Strategy</h3>
                      <p>Open: {trade.timestamp_open}</p>
                      <p>Close: { (signal.timestamp >= trade.timestamp_close) ? trade.timestamp_close : '' }</p>
                      <p>Position: <strong>{trade.position}</strong></p>
                      <p>Entry: {trade.entry}</p>
                      <p>Exit: {trade.exit}</p>
                      <p>Conf.: {trade.confidence}</p>
                      <p>
                        Trade PnL: <strong style={{ color: (trade.timestamp_close && signal.timestamp >= trade.timestamp_close) ? (trade.pnl > 0 ? '#6897D6' : 'lightcoral') : (signal.unrealized_pnl > 0 ? '#6897D6' : 'lightcoral') }}>
                        { trade.timestamp_close ? (signal.timestamp >= trade.timestamp_close) ? trade.pnl : signal.unrealized_pnl : signal.unrealized_pnl }
                        </strong>
                      </p>
                      <p style={{ marginTop: '15px', fontSize: '0.6em', color: 'gray' }}>{trade.timestamp}</p>
                    </div>
                  ))}
                  {
                  relevantSecondStratTrades.map((trade, idx) => (
                    <div
                      key={idx}
                      className={`strategy-info ${getBackgroundColorClass(trade.position)}`}
                    >
                      <h3>StarBurster Strategy</h3>
                      <p>Open: {trade.timestamp_open}</p>
                      <p>Close: { (signal.timestamp >= trade.timestamp_close) ? trade.timestamp_close : '' }</p>
                      <p>Position: <strong>{trade.position}</strong></p>
                      <p>Entry: {trade.entry}</p>
                      <p>Exit: {trade.exit}</p>
                      <p>Conf.: {trade.confidence}</p>
                      <p>
                        Trade PnL: <strong style={{ color: (trade.timestamp_close && signal.timestamp >= trade.timestamp_close) ? (trade.pnl > 0 ? '#6897D6' : 'lightcoral') : (signal.unrealized_pnl_2 > 0 ? '#6897D6' : 'lightcoral') }}>
                        { trade.timestamp_close ? (signal.timestamp >= trade.timestamp_close) ? trade.pnl : signal.unrealized_pnl_2 : signal.unrealized_pnl_2 }
                        </strong>
                      </p>
                      <p style={{ marginTop: '15px', fontSize: '0.6em', color: 'gray' }}>{trade.timestamp}</p>
                    </div>
                  ))
                  }
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default SignalFeed;
